import clsx from "clsx";
import Error from "next/error";
export default function NotFound() {
	// Opinionated: do not record an exception in Sentry for 404
	return (
		<div className={clsx("text-black-01")}>
			<Error hasGetInitialPropsRun={false} statusCode={404} />
		</div>
	);
}
